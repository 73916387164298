let loaded = false;
window.onload = function () {
    loaded = true;
}

jQuery(document).ready(function ($) {
    "use strict";

    // Platform Detection
    const IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
        Android = navigator.userAgent.toLowerCase().indexOf("android") > -1,
        Firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
        Edge = navigator.userAgent.toLowerCase().indexOf('edge') > -1,
        IE = navigator.userAgent.toLowerCase().indexOf('msie ') > -1;

    // Browser Windows Sizes
    const browser = {
        w: document.documentElement.clientWidth,
        h: document.documentElement.clientHeight,
    };

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    $(window).on('resize orientationchange', () => {
        browser.w = document.documentElement.clientWidth;
        browser.h = document.documentElement.clientHeight;

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    if (browser.w <= 991) {
        $('.section-tech-partner .step-item').attr('data-aos-delay', 300);
    }

    /* ----------------------------------- Scrollbar CSS Variable ------------------------------------ */
    {
        $('a[href="#contact"]').on('click', function (e) {
            if (!loaded) {
                e.preventDefault();
            }
        })
    }

    /* ----------------------------------- Scrollbar CSS Variable ------------------------------------ */
    {
        const getScrollbarWidth = () => {
            let outer = document.createElement("div");
            outer.style.visibility = "hidden";
            outer.style.width = "100px";
            outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

            document.body.appendChild(outer);

            let widthNoScroll = outer.offsetWidth;
            // force scrollbars
            outer.style.overflow = "scroll";

            // add innerdiv
            let inner = document.createElement("div");
            inner.style.width = "100%";
            outer.appendChild(inner);

            let widthWithScroll = inner.offsetWidth;

            // remove divs
            outer.parentNode.removeChild(outer);

            return widthNoScroll - widthWithScroll;
        }


        if ($('body').outerHeight() <= document.documentElement.clientHeight) {
            document.documentElement.style.setProperty('--scroll-width', 0);
        } else {
            document.documentElement.style.setProperty('--scroll-width', getScrollbarWidth() + 'px');
        }
    }

    /* ----------------------------------- Mobile Menu ------------------------------------ */
    {
        // Open
        $(document).on('click', '.nav-main .nav-toggle', function (e) {
            e.preventDefault();
            $('.nav-side').addClass('active');
            scrollLock.disablePageScroll();
        });

        // Close
        $(document).on('click', '.nav-side__close', function (e) {
            e.preventDefault();
            $('.nav-side').removeClass('active');
            scrollLock.enablePageScroll();
        });

        // Mega Menu
        $('.nav-side .menu>li.mega-menu-wrap > a').on('click', function (e) {
            e.preventDefault();
            if (browser.w > 991) {
                return false;
            }
            $(this).siblings('.mega-menu').slideToggle('fast');
        });

        // Close Menu on Anchor Links
        $('.nav-side  .btn-primary').on('click', function (e) {
            if ($(".nav-side").hasClass('active')) {
                $('.nav-side').removeClass('active');
                scrollLock.enablePageScroll();
            }
        });
    }

    /* ----------------------------------- Fixed Menu ------------------------------------ */
    {

        const nav = $('.nav-main');
        const navFixed = nav.clone().addClass('fixed').insertAfter(nav).removeClass('active');
        let lastScroll = 0;
        $(window).on('scroll', function () {
            const scroll = $(this).scrollTop();
            if (scroll >= 450) {
                if (scroll > lastScroll) {
                    navFixed.removeClass('active');
                    $('.mega-menu.active').removeClass('active');
                    $('.mega-menu-wrap.active').removeClass('active');
                } else {
                    navFixed.addClass('active');

                }
            } else {
                navFixed.removeClass('active');
                $('.mega-menu.active').removeClass('active');
                $('.mega-menu-wrap.active').removeClass('active');
            }
            lastScroll = scroll;
        });
    }

    /* ----------------------------------- Mega Menu ------------------------------------ */
    {
        // Services Mega Menu
        $('.mega-menu__services').appendTo('.services-mega-menu');

        // Industries Mega Menu
        $('.mega-menu__solutions').appendTo('.solutions-mega-menu');

        // Mega Menu
        $('.nav-main .mega-menu-wrap').each(function () {
            if (browser.w <= 991) {
                return false;
            }

            const megaMenu = $(this).find('.mega-menu');
            const link = megaMenu.parents('.mega-menu-wrap');

            // Show Mega Menu
            megaMenu.show();

            function setMegaMenuHeight() {
                $('.mega-menu').css({
                    'max-height': `${browser.h - $('.nav-main').outerHeight()}px`
                });
            }

            setMegaMenuHeight();
            $(window).on('scroll resize orientationchange', function () {
                setMegaMenuHeight();
            });

            // Triangle Position
            let offset = link.offset().left - ((browser.w - megaMenu.outerWidth()) / 2) + (link.width() / 2) - (52.5 / 2);
            megaMenu.find('.triangle').css('left', offset + 'px');

            // Open Mega Menu
            link.find('a').eq(0).on('click', function (e) {
                e.preventDefault()
                let el = $(e.target);
                if (el.hasClass('mega-menu') || el.parents('.mega-menu').length) {

                } else {

                    if ($(this).parents('.mega-menu-wrap').hasClass('active')) {
                    } else {
                        $('.nav-main .mega-menu-wrap.active').removeClass('active');
                        $('.nav-main .mega-menu.active').removeClass('active');
                    }

                    link.toggleClass('active');
                    megaMenu.toggleClass('active');
                }
            });

        });

        // Click Outside
        $(document).on('click', function (e) {
            let el = $(e.target);
            if ($('.mega-menu.active').length) {
                if (
                    !el.is('.mega-menu.active') &&
                    !el.is('.mega-menu-wrap.active') &&
                    !el.parents('.mega-menu-wrap.active').length &&
                    !el.parents('.mega-menu.active').length
                ) {
                    $('.mega-menu.active').removeClass('active');
                    $('.mega-menu-wrap.active').removeClass('active');
                }
            }
        });


    }

    /* ----------------------------------- Home Hero Header ------------------------------------ */
    {
        $('.hero-home').each(function () {
            const section = $(this);

            const imgOriginal = section.find('.img-original');
            const imgCopy = section.find('.img-copy');
            const timing = 15;

            let xMax2 = '35%';
            let xMax = '0%';
            let xMin = '-35%';

            xMax2 = '0%';
            xMax = '0%';
            xMin = '0%';

            function animate1() {
                TweenMax.fromTo(imgOriginal, timing, {
                    x: xMax,
                    y: '0%',
                }, {
                    x: xMin,
                    y: '-100%',
                    ease: Linear.easeNone,
                    onComplete: function () {

                        TweenMax.fromTo(imgOriginal, timing, {
                            x: xMax2,
                            y: '100%',
                        }, {
                            ease: Linear.easeNone,
                            x: xMax,
                            y: '0%',
                            onComplete: function () {
                                animate1();
                            }
                        });

                    }
                });
            }

            function animate2() {
                TweenMax.fromTo(imgCopy, timing, {
                    x: xMax2,
                    y: '100%',
                }, {
                    x: xMax,
                    y: '0%',
                    ease: Linear.easeNone,
                    onComplete: function () {

                        TweenMax.fromTo(imgCopy, timing, {
                            x: xMax,
                            y: '0%'
                        }, {
                            ease: Linear.easeNone,
                            x: xMin,
                            y: '-100%',
                            onComplete: function () {
                                animate2();
                            }
                        });

                    }
                });
            }

            animate1();
            animate2();
        });
    }

    /* ----------------------------------- Our Works Slider ------------------------------------ */
    {
        $('.works-slider-wrapper').each(function () {
            const wrap = $(this);
            const slider = $(this).find('.works-slider');

            let args = {
                init: false,
                direction: 'horizontal',
                centeredSlides: true,
                slidesPerView: 'auto',
                speed: 500,
                grabCursor: true,
                freeMode: false,
                loop: true,
                initialSlide: 1,
                loopedSlides: slider.find('.swiper-slide').length,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            };

            if (wrap.attr('data-nav') == 'dots') {
                args.pagination = {
                    el: wrap.find('.dots')[0],
                    type: 'bullets',
                    clickable: true,
                }
            } else {
                args.pagination = {
                    el: wrap.find('.fraction')[0],
                    type: 'fraction',
                };
            }

            let sliderSwiper = new Swiper(slider[0], args);
            sliderSwiper.init();


            slider.find('.swiper-slide').on('click', function (e) {
                if (!$(this).hasClass('swiper-slide-active')) {
                    e.preventDefault();
                }
                if ($(this).hasClass('swiper-slide-prev')) {
                    sliderSwiper.slidePrev();
                }
                if ($(this).hasClass('swiper-slide-next')) {
                    sliderSwiper.slideNext();
                }
            });

        });
    }

    /* ----------------------------------- Categories Slider ------------------------------------ */
    {
        $('.categories-slider__wrapper').each(function () {
            const wrap = $(this);
            const slider = $(this).find('.categories-slider');

            let args = {
                init: false,
                direction: 'horizontal',
                slidesPerView: 'auto',
                speed: 500,
                grabCursor: true,
                freeMode: true,
            };

            let sliderSwiper = new Swiper(slider[0], args);
            sliderSwiper.init();

        });
    }

    /* ----------------------------------- Instagram Slider ------------------------------------ */
    {
        $('.insta-slider__wrapper').each(function () {
            const wrap = $(this);
            const slider = $(this).find('.insta-slider');

            let args = {
                init: false,
                slidesPerView: 'auto',
                direction: 'horizontal',
                speed: 500,
                grabCursor: true,
                freeMode: true,
                loop: false,
            };

            if (browser.w <= 565) {
                let sliderSwiper = new Swiper(slider[0], args);
                sliderSwiper.init();
            }

        });
    }

    /* ----------------------------------- Testimonials Slider ------------------------------------ */
    {
        $('.testimonials-slider-wrap').each(function () {
            const wrap = $(this);
            const slider = $(this).find('.testimonials-slider');

            let args = {
                init: false,
                direction: 'horizontal',
                loop: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                speed: 500,
                grabCursor: true,
                freeMode: false,
                loopedSlides: 10,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: wrap.find('.dots')[0],
                    type: 'bullets',
                    clickable: true,
                }
            };


            let sliderSwiper = new Swiper(slider[0], args);
            sliderSwiper.init();

            slider.find('.swiper-slide').on('click', function (e) {
                if (!$(this).hasClass('swiper-slide-active')) {
                    e.preventDefault();
                }
                if ($(this).hasClass('swiper-slide-prev')) {
                    sliderSwiper.slidePrev();
                }
                if ($(this).hasClass('swiper-slide-next')) {
                    sliderSwiper.slideNext();
                }
            });

        });
    }

    /* ----------------------------------- Clients Slider ------------------------------------ */
    {
        $('.clients-slider-wrap').each(function () {
            const wrap = $(this);
            const slider = $(this).find('.clients-slider');

            let args = {
                init: false,
                direction: 'horizontal',
                loop: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                speed: 500,
                grabCursor: true,
                loopedSlides: 10,
                freeMode: true,
                breakpoints: {
                    100: {
                        speed: 5000,
                        autoplay: {
                            delay: 0,
                            disableOnInteraction: false,
                        },
                    },
                    992: {
                        autoplay: false,
                        speed: 500,
                    },
                }
            };

            let sliderSwiper = false;

            function initSlider() {
                if (browser.w <= 991) {
                    sliderSwiper = new Swiper(slider[0], args);
                    sliderSwiper.init();
                    sliderSwiper.autoplay.start();


                    $('.swiper-container').hover(function () {
                        if (sliderSwiper && sliderSwiper.autoplay) {
                            sliderSwiper.autoplay.stop();
                        }
                    }, function () {
                        if (sliderSwiper && browser.w <= 991) {
                            sliderSwiper.autoplay.start();
                        }
                    });
                }
            }

            initSlider();

            $(window).on('resize orientationchange', function () {
                if (browser.w <= 991) {
                    if (sliderSwiper) {
                        sliderSwiper.destroy();
                    }
                    initSlider();
                } else {
                    if (sliderSwiper) {
                        sliderSwiper.destroy();
                    }
                }
            });

        });
    }

    /* ----------------------------------- Parallax ------------------------------------ */
    {
        {
            let parallaxItems = $('.move-parallax');

            let parallaxMove = (e, target, movement, parent) => {
                var relX = e.pageX - parent.offset().left;
                var relY = e.pageY - parent.offset().top;
                TweenMax.to(target, 1, {
                    x: (relX - parent.width() / 2) / parent.width() * movement,
                    y: (relY - parent.height() / 2) / parent.height() * movement
                });
            }

            parallaxItems.each(function () {
                let target = $(this);
                let scene = target.parents('*[data-parallax-scene]').eq(0);

                scene.on('mousemove', function (e) {
                    if (scene.visible(true)) {
                        parallaxMove(e, target, target.attr('data-parallax-move'), scene);
                    }
                });

            });
        }
        {
            let parallaxItems = $('.scroll-parallax');

            // Parallax on window scroll
            let parallaxScroll = (el, offset, coff) => {
                TweenMax.to(el, 1, {
                    y: Number(offset) / Number(coff)
                });
            }

            parallaxItems.each(function () {
                let target = $(this);
                let scene = target.parents('*[data-parallax-scene]').eq(0);

                $(window).on('scroll', function () {
                    if (scene.visible(true)) {
                        let offset = $(this).scrollTop();
                        if (offset <= Number(scene.offset().top) + scene.outerHeight() + 100) {
                            parallaxScroll(target, offset, target.attr('data-scroll-coff'));
                        }
                    }
                });

            });
        }
    }

    /* ----------------------------------- Goal & Role Slider ------------------------------------ */
    {
        if ($('.goal-slider').length) {

            let roleSwiper = false;
            let goalSwiper = false;

            function sliderInit() {
                if (browser.w > 991) {
                    $('.goal-slider .swiper-wrapper .swiper-slide').on('click', function (e) {
                        e.preventDefault();

                        const index = $(this).attr('data-slide');
                        $(this).addClass('active')
                        $(this).siblings('.swiper-slide').removeClass('active');

                        $('.role-slider .swiper-slide').each(function () {
                            if ($(this).attr('data-slide') == index) {
                                $(this).addClass('active');
                                $(this).siblings('.swiper-slide').removeClass('active');
                            }
                        });


                    });
                } else {
                    let goalArgs = {
                        direction: 'horizontal',
                        loop: true,
                        slidesPerView: 1,
                        speed: 600,
                        lazy: true,
                        pagination: {
                            el: $('.goal-slider-wrap .dots')[0],
                            type: 'bullets',
                            clickable: true,
                        }
                    };
                    goalSwiper = new Swiper('.goal-slider', goalArgs);

                    let roleArgs = {
                        direction: 'horizontal',
                        loop: true,
                        slidesPerView: 1,
                        speed: 600,
                        lazy: true,
                        effect: 'fade',
                        autoHeight: browser.w >= 992 ? false : true,
                        fadeEffect: {
                            crossFade: true
                        },
                        pagination: {
                            el: $('.role-slider-wrap .dots')[0],
                            type: 'bullets',
                            clickable: true,
                        }
                    };
                    roleSwiper = new Swiper('.role-slider', roleArgs);

                    goalSwiper.controller.control = [roleSwiper];
                    roleSwiper.controller.control = [goalSwiper];
                }
            }

            sliderInit();


            $(window).on('resize orientationchange', function () {
                if (roleSwiper) {
                    roleSwiper.destroy();
                }
                if (goalSwiper) {
                    goalSwiper.destroy();
                }
                sliderInit();
            });


        }
    }

    /* ----------------------------------- Contact Form ------------------------------------ */
    {
        function validateEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        $('.contact-form').on('submit', function (e) {
            e.preventDefault();

            const form = $(this);
            let statusRow = form.find('.status-row');
            let statusRowField = statusRow.find('.form-field');

            statusRow.removeClass('active success');

            let name = form.find('input[name="FNAME"]').val();
            let email = form.find('input[name="EMAIL"]').val();
            let message = form.find('textarea[name="message"]').val();

            if (!name.length) {
                statusRowField.text('Please, fill all the fields.')
                statusRow.addClass('active');
                form.find('input[name="name"]').addClass('invalid');
                return false;
            } else {
                form.find('input[name="name"]').removeClass('invalid');
            }

            if (!message.length) {
                statusRowField.text('Please, fill all the fields.')
                statusRow.addClass('active');
                form.find('textarea[name="message"]').addClass('invalid');
                return false;
            } else {
                form.find('textarea[name="message"]').removeClass('invalid');
            }

            if (!validateEmail(email)) {
                statusRowField.text('Please, enter a valid email address')
                statusRow.addClass('active');
                form.find('input[name="email"]').addClass('invalid');
                return false;
            } else {
                form.find('input[name="email"]').removeClass('invalid');
            }

            const hookUrl = atob('aHR0cHM6Ly9ob29rcy5zbGFjay5jb20vc2VydmljZXMvVDVZTVVQMEFKL0JLMjlWSEtNMC9QZlVLczNMMmlLeVIzMHhjZ05tMHhlTWk=');
            const body = {
                name,
                email,
                message,
            };
            const source = window.location.href;
            const data = JSON.stringify({
                text: `New RFQ from ${source}:\n\n` + Object.keys(body).map(key => {
                    return `> *${key}*:\n> ${body[key]} \n\n`;
                }).join('\n'),
            });

            $.ajax({
                url: hookUrl,
                data: data,
                type: 'POST',
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend: function (xhr) {
                    form.find('button[type=submit]').attr('disabled', true);
                    $('body').addClass('wait loading');
                },
                complete: function (response) {
                    if (response.responseText == 'ok') {
                        statusRowField.text('Thank you for your message. It has been sent.')
                        statusRow.addClass('success active');

                        // Reset Form
                        form.trigger('reset');

                        // Show Notification
                        $('.notification--success').addClass('active');
                    } else {
                        statusRowField.text('Something went wrong, please try again')
                        statusRow.addClass('active');
                    }
                    form.find('button[type=submit]').attr('disabled', false);
                    $('body').removeClass('wait loading');
                }
            });


        });

        // Close Notification
        $('.notification .btn-close').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.notification').removeClass('active');
        });

        // Click outside Notification
        $(document).on('click', function (e) {
            if ($('.notification.active').length) {

                if (!$(e.target).parents('.notification.active').length) {
                    $('.notification.active').removeClass('active');
                }

            }
        });
    }

    /* ----------------------------------- AOS ------------------------------------ */
    {
        AOS.init({
            once: true,
            duration: 600,
            easing: 'slow-easing'
        });
    }

    /* ----------------------------------- Counter ------------------------------------ */
    {
        if ($('.count').length) {
            $(window).on('scroll', function () {
                $('.count').each(function () {
                    if ($(this).visible(true) && !$(this).hasClass('animated')) {
                        $(this).addClass('animated');

                        $(this).prop('Counter', 0).animate({
                            Counter: Number($(this).text())
                        }, {
                            duration: 2500,
                            easing: 'swing',
                            step: function (now) {
                                Number($(this).text(Math.ceil(now)));
                            }
                        });

                    }
                });
            });
        }
    }

    /* ----------------------------------- Portfolio Load More ------------------------------------ */
    {
        $('.btn-load-works').on('click', function (e) {
                e.preventDefault();
                const btn = $(this);

                // Arguments
                let page = Number(btn.attr('data-paged'));
                let count = Number($('.portfolio-item:not(.portfolio-item--cta)').length);

                $.ajax({
                    url: PHP.ajaxurl,
                    data: {
                        'action': 'works_load_more',
                        'page': page,
                        'count': count,
                        'nonce_code': PHP.works_load_more_nonce,
                    },
                    type: 'POST',
                    beforeSend: function (xhr) {
                        $('body').addClass('wait');
                        btn.attr('disabled', true);
                    },
                    success: function (data) {
                        btn.attr('data-page', page + 1);
                        //  $(data).hide().insertBefore($('.portfolio-item--cta')).fadeIn('normal');
                        $(data).hide().appendTo($('.portfolio-loop')).fadeIn('normal');

                        if (Number(btn.attr('data-max-pages')) === Number(page + 1)) {
                            btn.hide();
                        }
                    },
                    complete: function () {
                        setTimeout(() => {
                            $('body').removeClass('wait');
                            btn.attr('disabled', false);
                        }, 150);
                    }
                });

            }
        );
    }

    /* ----------------------------------- Posts Load More ------------------------------------ */
    {
        $('.btn-load-posts').on('click', function (e) {
            e.preventDefault();

            const btn = $(this);
            let page = btn.attr('data-page');

            $.ajax({
                url: PHP.ajaxurl,
                data: {
                    'action': 'posts_load_more',
                    'query': PHP.posts,
                    'page': page,
                    'nonce_code': PHP.posts_load_more_nonce,
                },
                type: 'POST',
                beforeSend: function (xhr) {
                    $('body').addClass('wait');
                    btn.attr('disabled', true);
                },
                success: function (data) {
                    btn.attr('data-page', Number(page) + 1);
                    $(data).hide().appendTo($('.ajax-container')).fadeIn('normal');
                    if (Number(btn.attr('data-pages')) == Number(btn.attr('data-page'))) {
                        btn.hide();
                    }
                },
                complete: function () {
                    setTimeout(() => {
                        $('body').removeClass('wait');
                        btn.attr('disabled', false);
                    }, 150);
                }
            });

        });
    }

    /* ----------------------------------- Posts Rating ------------------------------------ */
    {
        $('.rmp-rating-widget__icons-list__icon').each(function () {
            $(this).append(`<span class="description">${$(this).attr('data-descriptive-rating')}</span>`);
        });

        $('.rmp-heading.rmp-heading--title').html('<span>Do you like</span> this article?');

        $('.js-rmp-rating-widget:not(.rmp-rating-widget--has-rated) .js-rmp-rating-item').on('click', function () {

            // Show Notification
            setTimeout(() => {
                $('.notification--post-rating').addClass('active');
            }, 50)

        });

        if (browser.w <= 991) {
            $('.post-rating').appendTo('.post-single__content');
        }
    }

    /* ----------------------------------- Post Single Sidebar ------------------------------------ */
    {
        if (browser.w > 991 && $('.post-single__content, .post-single__sidebar').length) {
            $('.post-single__content, .post-single__sidebar').theiaStickySidebar({
                additionalMarginTop: 80,
                additionalMarginBottom: 30
            });
        }
    }

    /* ----------------------------------- Services Tabs ------------------------------------ */
    {
        if ($('.tabs-wrap').length) {
            $('.acc-item__header').on("click", function (e) {
                e.preventDefault();


                const item = $(this).parents('.acc-item');
                const tabs = item.parents('.tabs-wrap');
                const index = item.attr('data-item');

                if (!item.hasClass('active')) {
                    tabs.find('.acc-item').each(function () {
                        $(this).removeClass('active');
                        $(this).find('.acc-item__body').slideUp('fast')
                    });
                    tabs.find('.img-wrap').removeClass('active');


                    item.find('.img-wrap').addClass('active');
                    item.addClass('active');
                    tabs.find(`.img-wrap[data-item=${index}]`).addClass('active').hide().fadeIn('fast');
                    item.find('.acc-item__body').slideDown('fast');
                }

            });
        }
    }

});
